<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        v-if="!userData.username"
        variant="outline-primary"
        size="sm"
        href="/login"
      >
        <b>Login</b>
      </b-button>
      <b-button
        v-if="!userData.username"
        variant="primary"
        size="sm"
        href="/register"
      >
        Sign Up
      </b-button>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p
              v-if="userData.username"
              class="user-name font-weight-bolder mb-0"
            >
              {{ userData.fullName || userData.displayname }}
            </p>
            <span
              v-if="userData.username"
              class="user-status"
            >{{ userData.role }}</span>
          </div>
          <b-avatar
            v-if="userData.username"
            size="40"
            :src="userAvatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'pages-account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item
          :to="{ name: 'billing' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Billing</span>
        </b-dropdown-item> -->
        <b-dropdown-item
          :href="'/'+userData.username"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="VideoIcon"
            class="mr-50"
          />
          <span>My Channel</span>
        </b-dropdown-item>
        <b-dropdown-item
          to="/downloads"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span>Downloads</span>
        </b-dropdown-item>
        <b-dropdown-item
          :href="'https://brimestats.com/channel/'+userData.username"
          target="_blank"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="BarChart2Icon"
            class="mr-50"
          />
          <span>My Stats</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="'/'+userData.username + '/clips'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ScissorsIcon"
            class="mr-50"
          />
          <span>My Clips</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          :to="'/billing'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Billing</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { avatarText } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line

export default {
  components: {
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    function parseJwt(token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
      return JSON.parse(jsonPayload)
    }
    if (localStorage.getItem('accessToken')) {
      const userData = parseJwt(localStorage.getItem('accessToken'))
      userData.role = 'user'
      // eslint-disable-next-line
      userData.ability = [{ action: 'manage', subject: 'all' }]
      return {
        userData,
        avatarText,
        userAvatar: '',
      }
    }
    return {
      userData: {},
      avatarText: {},
      userAvatar: '',
    }
  },
  async mounted() {
    if (localStorage.getItem('accessToken')) {
      const { avatar } = await window.brime.getUser()
      this.userAvatar = avatar
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      window.brime.logout()
      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
<style scoped>
.btn {
 margin-left: 5px;
}
</style>
